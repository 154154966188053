import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import auth from './module/auth';
import service from './module/service';
import common from './module/common';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    service,
    common
  },
  plugins: [createPersistedState()],
  state: {
    detailsStore: {

      make_id: null,
      model_id: null,
      variant_id: null,
      year:null,
      make_name: "",
      model_name: "",
      variant_name: "",

      vehicle_type_id: null,
      number_of_seats: null,
      number_of_cylinders_id: null,
      tonnage_id: null,
      vehicle_value: null,
      vehicle_type_name:"",
      number_of_cylinders_name: "",
      tonnage_name: "",

      insurance_type: "",
      quote_id: null,
      insurer: Object,
      premium: null,
    },
    isFoundVehicleDetails: true,
    savedInputs: {
      ruleForm: null,
      vehicleData: null,
      insuranceType: null
    }
    
  },
  getters: {},
  mutations: {
    setMakedata(state, payloads) {
      state.detailsStore.make_id = payloads.make_id;
      state.detailsStore.model_id  = payloads.model_id;
      state.detailsStore.variant_id = payloads.variant_id;
      state.detailsStore.year = payloads.year;
      state.detailsStore.make_name = payloads.make_name;
      state.detailsStore.model_name = payloads.model_name;
      state.detailsStore.variant_name = payloads.variant_name;
      state.detailsStore.insurance_type = payloads.insurance_type;

      state.detailsStore.vehicle_type_name = payloads.vehicle_type_name;
      state.detailsStore.number_of_cylinders_name = payloads.number_of_cylinders_name;
      state.detailsStore.tonnage_name = payloads.tonnage_name;
    },
    setInsurer(state, payloads) {
      state.detailsStore.insurer = payloads;
    },
    setVehicleDetailsStatus(state, payloads) {
      state.isFoundVehicleDetails = payloads
    },
    setSavedInputs(state, payloads) {
      state.savedInputs['ruleForm'] = payloads['ruleForm']
      state.savedInputs['vehicleData'] = payloads['vehicleData']
    },
    setSavedInputInsuranceType(state, payloads) {
      state.savedInputs['insuranceType'] = payloads
    },
    resetStore(state) {
      state.detailsStore = {
    
          make_id: null,
          model_id: null,
          variant_id: null,
          year:null,
          make_name: "",
          model_name: "",
          variant_name: "",
    
          vehicle_type_id: null,
          number_of_seats: null,
          number_of_cylinders_id: null,
          tonnage_id: null,
          vehicle_value: null,
          vehicle_type_name:"",
          number_of_cylinders_name: "",
          tonnage_name: "",
    
          insurance_type: "",
          quote_id: null,
          insurer: Object,
          premium: null,
      }
      state.isFoundVehicleDetails = true
      state.savedInputs = {
        ruleForm: null,
        vehicleData: null
      }
    },
  },  
  actions: {}
});


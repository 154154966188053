import Vue from 'vue'
import VueRouter from 'vue-router'

/* Layout */
import Layout from '@/layout';
import LoginLayout from '@/layout/login';
import index from "vuex";


Vue.use(VueRouter)

const routes = [
    // {
    //     path: '',
    //     component: Layout,
    //     redirect: '/',
    //     children: [
    //         {
    //             path: '/',
    //             component: () => import('@/views/home/SearchVehicleForm'),
    //             name: 'index',
    //             // component: index
    //         },
    //     ]
    // },

    {
        path: '',
        component: Layout,
        redirect: '/',
        children: [
            {
                path: '/',
                component: () => import('@/views/home/index'),
                name: 'quote',
                meta: {title: 'Quote', icon: 'quote', noCache: false},
            },


        ],
    },

    {
        path: '/proposal',
        component: Layout,
        redirect: '/proposal-form',
        children: [
            {
                path: '/',
                component: () => import('@/views/proposal/ProposalSubmitForm'),
                name: 'proposal-form',
                meta: {title: 'Proposal Form', icon: 'proposal-form', noCache: false},
            },


        ],
    },
    {
        path: '/payment-success',
        component: Layout,
        redirect: '/payment-success',
        children: [
            {
                path: '/',
                component: () => import('@/views/proposal/PaymentSuccess'),
                name: 'payment-success',
                meta: {title: 'Payment Sucess', icon: 'payment-success', noCache: false},
            },


        ],
    },
    {
        path: '/payment-fail',
        component: Layout,
        redirect: '/payment-fail',
        children: [
            {
                path: '/',
                component: () => import('@/views/proposal/PaymentFail'),
                name: 'payement-fail',
                meta: {title: 'Payment Fail', icon: 'payment-fail', noCache: false},
            },


        ],
    },
    {
        path: '/proposal-edit',
        component: Layout,
        redirect: '/proposal-edit',
        children: [
            {
                path: '/',
                component: () => import('@/views/proposal/ProposalEdit'),
                name: 'proposal-edit',
                meta: {title: 'Proposal-edit', icon: 'proposal-edit', noCache: false},
            },


        ],
    },




]


const createRouter = () => new VueRouter({
    mode: 'history', // require service support
    scrollBehavior: () => ({y: 0}),
    base: process.env.MIX_LARAVUE_PATH,
    routes: routes,
});

const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter();
    router.matcher = newRouter.matcher; // reset router
}

export default router;


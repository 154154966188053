/**
|--------------------------------------------------------------------------
| Vue Component : Header
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:15 PM)
|
*/
<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "HeaderView"
}
</script>

<style scoped>

</style>
import '@/bootstrap';
import { Message } from 'element-ui';

import { isLogged, setLogged } from '@/utils/auth';

; // 'application/json;charset=utf-8',

// Create axios instance
const service = window.axios.create({
  baseURL: process.env.VUE_APP_API_URL+'/user',
  timeout: 1000*60*5, // Request timeout
});

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = isLogged();
    if (token) {
      console.log("Authorized"); // for debug
      config.headers['Authorization'] =  isLogged(); // Set JWT token
    }
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    let message = error.message;
    console.log(error);
    if (error.response.data && error.response.data.errors) {
      message = error.response.data.errors;
    } else if (error.response.data && error.response.data.error) {
      message = error.response.data.error;
    }

    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000,
    });

    return Promise.reject(error);
  }
);

export default service;

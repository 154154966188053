<template>
  <div id="app">
    <div class="mask" v-if="!isFoundVehicleDetails"></div>
    <router-view/>
  </div>
</template>

<script>
export default {
  computed: {
    isFoundVehicleDetails() {
      return this.$store.state.isFoundVehicleDetails;
    }
  }
}
</script>

<style lang="scss">
#app, .mask {
  min-height: 100vh;
}
.mask {
  background: #000;
  opacity: 0.6;
  position: absolute;
  z-index: 100;
  width: 100%;
}

</style>

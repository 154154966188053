import Vue from 'vue'
import App from './views/App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie';
import ElementUI from 'element-ui';
import i18n from './lang'; // Internationalization
import 'element-ui/lib/theme-chalk/index.css';
window.$ = window.jQuery = require('jquery');
Vue.use(VueI18n)
Vue.config.productionTip = false
// window.url = 'http://127.0.0.1:8000';
window.url = 'https://mapping.orbee.com.qa';
window.policyurl = 'https://engine.orbee.com.qa';
// window.policyurl = 'http://127.0.0.1:8001';

Vue.use(require('vue-moment'));
Vue.use(ElementUI, {
  size: Cookies.get('size') || 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value),
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

import request from '@/utils/request';

export function login(query) {
  return request({
    url: '/login',
    method: 'post',
    data:query,
  });

}
export function register(query) {
  return request({
    url: '/register',
    method: 'post',
    data:query,
  });
}


/**
|--------------------------------------------------------------------------
| Vue Component : index
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:18 PM)
|
*/
<template>
  <MainView></MainView>
</template>

<script>
import FooterPage from './components/FooterView'
import HeaderPage from './components/HeaderView'
import MainView from './components/MainView'

export default {
  components:{FooterPage,HeaderPage,MainView},
  name: "index"
}
</script>

<style lang="css" scoped>
@import "~@/styles/style.css";

</style>
/**
 |--------------------------------------------------------------------------
 | File Name : auth.js
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for
 | Created By rahul on (21 Mar 2022 at 8:18 AM)
 |
 */
import {login,register} from "@/api/auth";
import { isLogged, setLogged ,removeToken} from '@/utils/auth';

const state = {
    user: null,
    token: null,
};

const getters = {
    isAuthenticated: state => !!state.user,
    StateUser: state => state.user,
    StateToken: state => state.token,
};

const actions = {
    async Register({commit}, query) {
        return new Promise((resolve, reject) => {
            register(query)
                .then(response => {
                    if (response.status) {
                        this.commit('setUser', response)
                        resolve(response);
                    } else {
                        reject(response.message);
                    }
                });
        });
    },
    async LogIn({commit}, query) {
        return new Promise((resolve, reject) => {
            login(query)
                .then(response => {
                    if (response.status) {
                        this.commit('setUser', response)
                        resolve(response);
                    }else{
                        reject(response.message);
                    }
                });
        });
    },
    async LogOut({commit}){
        this.commit('LogOut');
        removeToken()
    }


};
const mutations = {
    setUser(state, data){
        state.user = data.data.user
        state.token = data.data.token_type+' '+ data.data.access_token
        setLogged(data.data.token_type+' '+ data.data.access_token)
    },

    LogOut(state){
        state.user = null
        state.token = null
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};

/**
|--------------------------------------------------------------------------
| Vue Component : Header
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:15 PM)
|
*/
<template>
 <header>

  <div class="site-header">
    <div class="site-container">
      <div class="logo-box">
        <a href="https://orbee.com.qa/"><img src="../../assets/plogo.png"/></a>
      </div>
      <div class="text-box">
        <div>
          <span>{{headerValues.insurance_type}}</span> Motor Insurance
        </div>
        <div class="line2">
          <span>{{headerValues.make_name}}</span> <span>{{headerValues.model_name}}</span> <span>{{headerValues.variant_name}},</span> <span>{{headerValues.year}}</span>
        </div>
        
      </div>
      
    </div>
    
  </div>

   <el-menu
       :default-active="activeIndex2"
       class="el-menu-demo"
       mode="horizontal"
       @select="handleSelect"
       background-color="#545c64"
       text-color="#fff"
       active-text-color="#ffd04b">
     <!-- <el-menu-item index="1">Orbee</el-menu-item> -->

   </el-menu>
 </header>

</template>


<script>


export default {
  name: "HeaderView",
  data() {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      // headerValues:{
      //   model_name:'',
      //   make_name:'',
      //   variant_name:'',
      //   year: null,
      // }
    };
  },
  methods: {
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    }
  },
  created() {
    let params;
    params = this.$store.state.detailsStore;
    //console.log(params);
    // this.headerValues.make_name = params.make_name;
    // this.headerValues.model_name = params.model_name;
    // this.headerValues.variant_name = params.variant_name;
    // this.headerValues.year = params.year;
    // this.headerValues.insurance_type = params.insurance_type;
    // this.headerValues.vehicle_type_name = params.vehicle_type_name;
  },
  computed: {
    headerValues() {
      return this.$store.state.detailsStore;
    }
  }
}
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display&display=swap');

body{margin: 0;font-family: 'Poppins', sans-serif;}
.el-menu--horizontal{border:0 !important;}
.site-header{min-height: 85px;background: #fafbfb; padding: 31px 0;}
.site-container{margin: 0 auto; max-width: 1230px; padding-left: 15px; padding-right: 15px;}
.site-header .site-container{ max-width: 1200px;}
.logo-box{float: left;}
.text-box{
  float: right; text-align: right; padding-top: 14px; font-size: 22px;font-family: 'DM Serif Display', serif;
}
.text-box .line2{ font-size: 17px; color: #999; font-family: 'Poppins', sans-serif;}
.hdd{font-family: 'DM Serif Display', serif;}
@media (min-width: 768px){
  .site-container {width: 750px;}
}
@media (min-width: 992px){
  .site-container {width: 970px;}
}
@media (min-width: 1200px){
  .site-container {width: 1900px;}
}

@media (max-width: 767px){
  .site-header{overflow: hidden;}
  .logo-box{ width:100%; text-align: center;}
  .text-box{ width:100%; text-align: center;}

}



</style>
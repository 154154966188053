/**
 |--------------------------------------------------------------------------
 | File Name : service.js
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for
 | Created By rahul on (21 Mar 2022 at 8:18 AM)
 |
 */


const state = {
    selectedVendorLocation: null,
    selectedVendorServices: null,
    selectedSlots: null,
    selectedTotalTime: null,
    selectedAllServices: null,
    selectSlotIds: null,
    paymentData: {},
};

const getters = {
    selectedVendorServices: state => state.selectedVendorServices,
    selectedVendorLocation: state => state.selectedVendorLocation,
    selectedAllServices: state => state.selectedAllServices,
    selectedSlots: state => state.selectedSlots,
    selectedTotalTime: state => state.selectedTotalTime,
    selectSlotIds: state => state.selectSlotIds,
    paymentData: state => state.paymentData,
};

const actions = {
    async selectVendorLocation({dispatch}, vendorLocation) {
         this.commit('setVendorLocations', vendorLocation)
    },

    async selectVendorServices({dispatch}, VendorServices) {
         this.commit('setVendorServices', VendorServices)
    },

    async selectedAllServices({dispatch}, VendorServices) {
        this.commit('setAllServices', VendorServices)
    },

    async selectTotalTime({dispatch}, TotalTime) {
        this.commit('setTotalTime', TotalTime)
    },

    async selectSlots({dispatch}, selectedSlots) {
        this.commit('setVendorSlots', selectedSlots)
    },
    async selectPaymentData({dispatch}, selectedSlots) {
        this.commit('setPaymentData', selectedSlots)
    },

    async selectSlotIds({dispatch}, selectedSlots) {
        this.commit('setSlotIds', selectedSlots)
    },



};
const mutations = {
    setVendorLocations(state, selectedVendorLocation){
        state.selectedVendorLocation = selectedVendorLocation
    },
    setVendorServices(state, selectedVendorServices){
        state.selectedVendorServices = selectedVendorServices
    },
    setTotalTime(state, TotalTime){
        state.selectedTotalTime = TotalTime
    },
    setVendorSlots(state, selectedSlots){
        state.selectedSlots = selectedSlots
    },

    setAllServices(state, selectedAllServices){
        state.selectedAllServices = selectedAllServices
    },

    setSlotIds(state, selectSlotIds){
        state.selectSlotIds = selectSlotIds
    },

    setPaymentData(state, paymentData){
        state.paymentData = paymentData
    },



};
export default {
    state,
    getters,
    actions,
    mutations
};

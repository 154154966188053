/**
 |--------------------------------------------------------------------------
 | File Name : common.js
 |--------------------------------------------------------------------------
 |
 | Usage : This File used for
 | Created By rahul on (21 Mar 2022 at 8:18 AM)
 |
 */

const state = {
    page: null,
};

const getters = {
    pageClass: state => state.page,
};

const actions = {
    async setPage({dispatch}, page) {
        this.commit('setPageClass', page)
    },
};
const mutations = {
    setPageClass(state, page){
        state.page = page
    },
};
export default {
    state,
    getters,
    actions,
    mutations
};

/**
|--------------------------------------------------------------------------
| Vue Component : Footer
|--------------------------------------------------------------------------
|
| Usage : This Component used for
| Created By rahul on (24 Feb 2022 at 12:16 PM)
|
*/
<template>
  <div>

  </div>
</template>

<script>
export default {
  name: "FooterView"
}
</script>

<style scoped>

</style>